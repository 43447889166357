@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}

@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }

  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

body {
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif !important;
}

.tool-header h1,
.tool-header .h1 {
  font-size: 2rem;
}
.tool-header {
  margin-bottom: 20px;
}

.tool-header h2,
.tool-header .h2 {
  font-size: 1.5rem;
}

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
}

#sidebar.active {
  margin-left: -250px;
}

a[data-toggle="collapse"] {
  position: relative;
}
#sidebarCollapse {
  margin: 10px;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
}

p {
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  /*color: #999;*/
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: all 0.3s;
}

#sidebar {
  /* don't forget to add all the previously mentioned styles here too */
  /*background: #7386D5;*/
  /*color: #fff;*/
  transition: all 0.3s;
}

#sidebar .sidebar-header {
  padding: 20px;
  /*background: #6d7fcc;*/
}

#sidebar ul.components {
  padding: 20px 0;
  /*border-bottom: 1px solid #47748b;*/
}

#sidebar ul p {
  /*color: #fff;*/
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
#sidebar ul li a:hover {
  /*color: #7386D5;*/
  /*background: #fff;*/
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  /*color: #fff;*/
  /*background: #6d7fcc;*/
}
ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  /*background: #6d7fcc;*/
}
textarea.dark {
  margin-right: auto;
  margin-left: auto;
}

.dark {
  background-color: #444;
  border-radius: 0.25rem;
  color: #fff;
  outline-color: #00bc8c;
}

.content {
  padding-bottom: 8.5rem; /* Footer height */
}

.footer {
  font-size: 87.5%;
  margin-top: 3.4286em;
  padding: 1.1429em;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 8.5rem;
}

.action-buttons {
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
}
#content {
  width: 100%;
}
.action-buttons {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.menu-link.active {
  color: #00bc8c;
  border: 1px solid #00bc8c;
}
#json-formatter {
  font-size: 14px;
  overflow-x: auto;
  white-space: nowrap;
}
#json-formatter .json-formatter-string {
  color: #00bc8c;
}

#json-formatter .json-formatter-key {
  color: #3498db;
}

.alert {
  margin-top: 10px;
}

#hashTable td {
  word-break: break-all;
}

.form-check-inline.custom-control {
  display: inline;
}

.blog {
  padding: 20px;
}

#emojis .emoji {
  cursor: pointer;
}
#emojis .emoji:hover {
  border: 1px solid #00bc8c;
}

.user-date-time-picker .rdtPicker {
  color: #fff;
  text-align: left;
  background-color: #222;
}

.user-date-time-picker .rdtPicker thead tr:first-of-type th:hover,
.user-date-time-picker .rdtPicker .rdtTimeToggle:hover,
.user-date-time-picker .rdtPicker .rdtDay:hover,
.user-date-time-picker .rdtPicker .rdtBtn:hover,
.user-date-time-picker .rdtPicker .rdtMonth:hover,
.user-date-time-picker .rdtPicker .rdtYear:hover {
  background: #333;
}

